import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';

import Hero from 'components/Hero';
import { resolveSlice } from 'utils/slice-resolver.js';
import useSiteMetadata from 'hooks/useSiteMetadata';

import css from './service.module.scss';

const Service = ({ data: { prismic }, location }) => {
  const { siteUrl } = useSiteMetadata();

  const { service } = prismic;
  const image = service.hero_imageSharp;
  const slices = service.body || [];

  return (
    <>
      <Helmet>
        <title>{`SwissDeCode - ${RichText.asText(service.name)}`}</title>
        <meta description={RichText.asText(service.description)} />
        <meta property="og:title" content={RichText.asText(service.name)} />
        <meta
          property="og:description"
          content={RichText.asText(service.description)}
        />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta property="og:image" content={service.hero_image.url} />
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <Hero
        image={image ? image.childImageSharp.fluid : null}
        logo={service.logo.url}
        logoClassName={css.heroLogo}
        title={service.name}
        description={service.description}
      />
      {slices.map(resolveSlice)}
    </>
  );
};

export default Service;

export const pageQuery = graphql`
  query ServiceBySlug($uid: String!) {
    prismic {
      service(lang: "pt-pt", uid: $uid) {
        _meta {
          uid
        }
        body {
          ... on PRISMIC_ServiceBodyContent_secton {
            primary {
              title
              type
            }
            fields {
              main
              icon
              content_text
              image
              imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            type
          }
          ... on PRISMIC_ServiceBodyFlow_section {
            type
            primary {
              title
              type
            }
            fields {
              icon
              name
              text
            }
          }
          ... on PRISMIC_ServiceBodyMosaic_section {
            type
            primary {
              bottom_image
              description
              top_left_image
              title
              top_right_image
              type
            }
            fields {
              button_label
              button_anchor
            }
          }
        }
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logo
        name
        description
      }
    }
  }
`;
